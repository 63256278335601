import DocumentMediaComponent from '@wix/thunderbolt-elements/src/components/DocumentMedia/viewer/DocumentMedia';
import DocumentMediaController from '@wix/thunderbolt-elements/src/components/DocumentMedia/viewer/DocumentMedia.controller';


const DocumentMedia = {
  component: DocumentMediaComponent,
  controller: DocumentMediaController
};


export const components = {
  ['DocumentMedia']: DocumentMedia
};

