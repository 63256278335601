import {
  IPlatformData,
  withCompController,
} from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  IDocumentMediaProps,
  IDocumentMediaMapperProps,
  IDocumentMediaStateValues,
  IDocumentMediaControllerProps,
} from '../DocumentMedia.types';

const useComponentProps = ({
  mapperProps,
  stateValues,
}: IPlatformData<
  IDocumentMediaMapperProps,
  IDocumentMediaControllerProps,
  IDocumentMediaStateValues
>): IDocumentMediaProps => {
  const {
    compId,
    language,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnClick = () => {
    const { link, title, image } = mapperProps;

    tryReportAnalyticsClicksBi(stateValues.reportBi, {
      language,
      trackClicksAnalytics,
      element_id: compId,
      elementTitle: title,
      value: link.docInfo?.name,
      details: { uri: image.uri },
      elementType: fullNameCompType,
      elementGroup: AnalyticsClicksGroups.Button,
    });
  };

  return {
    ...restMapperProps,
    reportBiOnClick,
  };
};

export default withCompController(useComponentProps);
